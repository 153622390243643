* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: fantasy;
  /* background: rgb(238, 174, 202); */
  /* background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  ); */
}
