.comingsoonPage {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.comingsoonContainer {
  // margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px solid #f0c8b2;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  gap: 20px;
}
.title {
  font-size: 30px;
  text-transform: capitalize;
  color: #5f5048;
  text-align: center;
}
.logoContainer {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20;
}
h4 {
  font-weight: 900;
}
h4,
p {
  text-align: center;
}
p {
  width: 80%;
  margin: auto;
  font-size: 13px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 690px) {
  .title {
    font-size: 14px;
  }
  .image2 {
    width: 100px;
  }
  .image {
    width: 100px;
    height: 100px;
  }
  .comingsoonContainer {
    width: 70%;
  }
}
@media screen and (max-width: 375px) {
  .comingsoonContainer {
    width: 100%;
  }
  h4 {
    font-weight: 900;
    line-height: 20px;
    font-size: 14px;
  }
  .textContainer {
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
